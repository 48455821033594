<template>
  <div class="planstat-examination-grid">
    <el-form inline>
      <el-form-item>
        <el-input v-model="searchText" type="text" placeholder="搜索"></el-input>
      </el-form-item>
    </el-form>
    <div>
      <template v-if="tenders.length">
        <div
          v-for="tender in filteredTenders"
          :key="tender.tenderGuid"
          class="tender-wrapper">
          <div class="tender-title">
            {{ tender.tenderName }}标段
          </div>
          <div
            v-for="(icon, index) in tender.iconList"
            :key="index"
            @click="handleClick(icon, tender)"
            class="folder-wrapper">
            <i class="iconfont iconfolder1"></i>
            <span class="title">{{ icon.name }}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import auth from '@/common/auth'
import tenderService from '@/services/tenderService'
import reportService from '@/services/reportService'
import { mapMutations } from 'vuex'

export default {
  name: 'PlanStatExaminationGrid',
  data () {
    return {
      searchText: '',
      tenders: [],
      iconList: [
        {
          name: '总体计划',
          type: 'masterPlan',
          url: 'yearPlan'
        },
        {
          name: '年计划',
          type: 'yearPlan',
          url: 'yearPlan'
        },
        {
          name: '月计划',
          type: 'monthPlan',
          url: 'yearPlan'
        },
        {
          name: '日统计',
          type: 'dateStat',
          url: 'yearPlan'
        },
        {
          name: '月统计',
          type: 'monthStat',
          url: 'yearPlan'
        },
        {
          name: '年统计',
          type: 'yearStat',
          url: 'yearPlan'
        }
      ],
      reports: []
    }
  },
  computed: {
    filteredTenders () {
      return this.tenders.filter(tender => tender.tenderName.indexOf(this.searchText) > -1)
    }
  },
  methods: {
    handleClick (icon, tender) {
      if (icon.url) {
        this.setTenderGuid(tender.tenderGuid)
        this.setExaminationType(icon.type)
        this.$router.push(icon.url)
      }
    },
    getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data.map(tender => {
              tender.iconList = this.iconList.filter(icon => this.reports.findIndex(report => report.tenderGuid === tender.tenderGuid && icon.name === report.planStatType) > -1)
              return tender
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err)
        })
    },
    getReports () {
      reportService.listByProjectGuid(auth.getUserInfo().projectGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.reports = res.data.data
            this.getTenders()
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    ...mapMutations('planStatExamination', {
      setPlanStatExaminationGuid: 'setPlanStatExaminationGuid',
      setTenderGuid: 'setTenderGuid',
      setTenderName: 'setTenderName',
      setYear: 'setYear',
      setMonth: 'setMonth',
      setDate: 'setDate',
      setExaminationCode: 'setExaminationCode',
      setExaminationType: 'setExaminationType',
      setApprovalUserGuid: 'setApprovalUserGuid'
    })
  },
  mounted () {
    this.getReports()
  }
}
</script>

<style scoped lang="scss">
.planstat-examination-grid {
  height: 100%;
  display: flex;
  flex-direction: column;
  & > div:last-child {
    flex: 1;
    overflow: scroll;
  }
}
.tender-wrapper {
  position: relative;
  border-top: 1px solid #ccc;
  margin-top: 40px;
  padding-top: 10px;
  height: 130px;
  clear: both;

  .tender-title {
    position: absolute;
    width: 100px;
    line-height: 20px;
    top: -10px;
    left: 30px;
    text-align: center;
    background-color: white;
    color: #777;
  }
}

.folder-wrapper {
  float: left;
  display: flex;
  width: 100px;
  height: 120px;
  margin: 10px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  i {
    display: block;
    color: rgba(255, 234, 147, .9);
    font-size: 80px;
    line-height: 80px;
    width: 80px;
    height: 80px;
  }

  i:hover {
    color: rgba(255, 239, 110, 1);
  }

  .title {
    text-align: center;
    font-size: 14px;
    color: #777;
    display: block;
  }
}
</style>
